.footer {
  font-family: 'Montserrat', sans-serif;
  font-size:70%;
  background: $main;
  padding: 10px 0;
  margin-top: 100px;
  text-align: center;
  color: #999999;

  a {
    text-decoration: none;
    margin: 0;
    color: #999999;
    &:hover{
      color: $sec;
    }
    &:active {
      color: $sec;
    }
  }
}

footer {
  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;

    li {
      border-left: solid 2px;
      display: inline-block;
      line-height: 1;
      margin-left: 1rem;
      padding-left: 1rem;

      &:first-child {
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
      }
    }
  }

  @media only screen and (max-width: $cut-small) {
    ul {
      li {
        border-left: 0;
        margin: 1rem 0 0 0;
        padding-left: 0;
        display: block;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

}