#about {
  font-size: 120%;
  width: 90%;
  margin: 0 auto;
  max-width: 960px;
  @media only screen and (min-width: $cut) {
    width: 80%;
  }


  .intro {
    border-bottom: 1px solid #EEEEEE;
  }

  div {
    margin: 0 20% 0 20%;
    @media only screen and (max-width: $cut) {
      margin: 0 12.5% 0 12.5%;
    }
  }
}

#headshot {
  height: 110px;
  width: 110px;
  float: right;
  padding:5px;
  margin: 25px 5px 10px 5px;
  background:#FFFFFF;
  border: 1px #EEEEEE solid;
}



@media only screen and (min-width: 600px) {
  #headshot {
    margin-top: -75px;
  }
}


// .intro {
//   margin: 1em auto 2em auto ;
//   h1 {
//     font-weight: 400;
//   }
//   border-bottom: 1px solid #EEEEEE;
// }

// .details {
//   margin: 0 20% 0 20%;
//   @media only screen and (max-width: $cut) {
//     margin: 0 12.5% 0 12.5%;
//   }
// }